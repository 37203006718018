.sliding-background {
  background: url("./assets/banners/buttonBanner.png") repeat-x;
  background-size: auto 50px;
  height: 50px;
  width: 2576px;
  animation: slide 30s linear infinite;
}

.sliding-background-reverse {
  background: url("./assets/banners/cardBanner.png") repeat-x;
  background-size: auto 80px;
  height: 80px;
  width: 2576px;
  animation: slide 30s linear infinite;
  animation-direction: reverse;
}

@keyframes slide {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-1692px, 0, 0);
  }
}
a {
  text-decoration: none !important;
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}
html,
body {
  width: 100vw;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
