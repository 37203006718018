:root {
    --downScale: 0.8;
    --downScaleY: 20px;
}

.left {
    position: relative;
}

.left.leftToRight {
    animation: leftL2R 500ms ease-out forwards;
}
.left.rightToPrimary {
    animation: leftR2P 500ms ease-out forwards;
}
.left.primaryToLeft {
    animation: leftP2L 500ms ease-out forwards;
}
.left.leftToPrimary {
    animation: leftL2P 500ms ease-out forwards;
}
.left.rightToLeft {
    animation: leftR2L 500ms ease-out forwards;
}
.left.primaryToRight {
    animation: leftP2R 500ms ease-out forwards;
}
.leftInitial {
    opacity: 1;
    transform: scale(var(--downScale)) translateY(var(--downScaleY));
}

.primary {
    position: relative;
}

.primary.primaryToLeft {
    animation: PrimaryP2L 500ms ease-out forwards;
}
.primary.primaryToRight {
    animation: primaryP2R 500ms ease-out forwards;
}
.primary.rightToPrimary {
    animation: primaryR2P 500ms ease-out forwards;
}
.primary.rightToLeft {
    animation: primaryR2L 500ms ease-out forwards;
}
.primary.leftToPrimary {
    animation: primaryL2P 500ms ease-out forwards;
}
.primary.leftToRight {
    animation: primaryL2R 500ms ease-out forwards;
}
.primaryInitial {
    z-index: 10;
    opacity: 1;
}

.right {
    position: relative;
}

.right.rightToPrimary {
    animation: rightR2P 500ms ease-out forwards;
}
.right.primaryToLeft {
    animation: rightP2L 500ms ease-out forwards;
}
.right.leftToRight {
    animation: rightL2R 500ms ease-out forwards;
}
.right.leftToPrimary {
    animation: rightL2P 500ms ease-out forwards;
}
.right.primaryToRight {
    animation: rightP2R 500ms ease-out forwards;
}
.right.rightToLeft {
    animation: rightR2L 500ms ease-out forwards;
}
.rightInitial {
    opacity: 1;
    transform: scale(var(--downScale)) translateY(var(--downScaleY));
}

/* ---------------------------------------------------------------------------- */
/* Left Card for mobile View */
@media screen and (max-width: 999px) {
    @keyframes leftP2R {
        0% {
            transform: translate(67px);
            opacity: 1;
            z-index: 10;
        }
        100% {
            transform: translate(130px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes leftR2L {
        0% {
            transform: translate(130px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
        }
        100% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes leftL2P {
        0% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
            z-index: 10;
        }
        100% {
            transform: translate(67px);
            opacity: 1;
            z-index: 10;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes leftP2L {
        0% {
            transform: translate(67px);
            opacity: 1;
        }
        100% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes leftR2P {
        0% {
            transform: translate(130px) scale(var(--downScale))
                translateY(var(--downScaleY));
            z-index: 10;
        }
        100% {
            transform: translate(67px);
            opacity: 1;
            z-index: 10;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes leftL2R {
        0% {
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
            opacity: 1;
        }
        100% {
            transform: translate(130px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
        }
    }
}
/* ---------------------------------------------------------------------------- */
/* Primary Card for mobile View */
@media screen and (max-width: 999px) {
    @keyframes PrimaryP2L {
        0% {
            opacity: 1;
            z-index: 10;
        }
        100% {
            transform: translate(-70px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes primaryP2R {
        0% {
            opacity: 1;
            z-index: 0;
        }
        100% {
            transform: translate(67px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes primaryR2P {
        0% {
            transform: translate(67px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 10;
        }
        100% {
            opacity: 1;
            z-index: 10;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes primaryR2L {
        0% {
            transform: translate(67px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: -1;
        }
        80% {
            z-index: -1;
        }
        100% {
            transform: translate(-70px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes primaryL2P {
        0% {
            transform: translate(-70px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
        100% {
            z-index: 10;
            opacity: 1;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes primaryL2R {
        0% {
            transform: translate(-70px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
        100% {
            transform: translate(67px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes rightR2P {
        0% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
            z-index: 10;
        }
        100% {
            transform: translate(-67px);
            opacity: 1;
            z-index: 10;
        }
    }
}
/* ---------------------------------------------------------------------------- */
/* Right card for mobile view */
@media screen and (max-width: 999px) {
    @keyframes rightP2L {
        0% {
            transform: translate(-67px);
            opacity: 1;
        }
        100% {
            transform: translate(-135px) translateY(var(--downScaleY))
                scale(var(--downScale));
            opacity: 1;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes rightL2R {
        0% {
            transform: translate(-135px) translateY(var(--downScaleY))
                scale(var(--downScale));
            opacity: 1;
            z-index: -1;
        }
        80% {
            z-index: -1;
        }
        100% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
            z-index: 0;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes rightL2P {
        0% {
            transform: translate(-135px) translateY(var(--downScaleY))
                scale(var(--downScale));
            opacity: 1;
            z-index: 10;
        }
        100% {
            transform: translate(-67px);
            opacity: 1;
            z-index: 10;
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes rightP2R {
        0% {
            transform: translate(-67px);
            opacity: 1;
        }
        100% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
        }
    }
}
@media screen and (max-width: 999px) {
    @keyframes rightR2L {
        0% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
            z-index: -1;
        }
        80% {
            z-index: -1;
        }
        100% {
            transform: translate(-135px) translateY(var(--downScaleY))
                scale(var(--downScale));
            opacity: 1;
        }
    }
}

/* ---------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------- */

/* Left Card for window View */
@media screen and (min-width: 1000px) {
    @keyframes leftP2R {
        0% {
            transform: translate(100px);
            opacity: 1;
            z-index: 10;
        }
        100% {
            transform: translate(200px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
        }
    }
}

@media screen and (min-width: 1000px) {
    @keyframes leftR2L {
        0% {
            transform: translate(200px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
        }
        100% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
        }
    }
}

@media screen and (min-width: 1000px) {
    @keyframes leftL2P {
        0% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
            z-index: 10;
        }
        100% {
            transform: translate(100px);
            opacity: 1;
            z-index: 10;
        }
    }
}
@media screen and (min-width: 1000px) {
    @keyframes leftP2L {
        0% {
            transform: translate(100px);
            opacity: 1;
        }
        100% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
        }
    }
}
@media screen and (min-width: 1000px) {
    @keyframes leftR2P {
        0% {
            transform: translate(200px) scale(var(--downScale))
                translateY(var(--downScaleY));
            z-index: 10;
        }
        100% {
            transform: translate(100px);
            opacity: 1;
            z-index: 10;
        }
    }
}

@media screen and (min-width: 1000px) {
    @keyframes leftL2R {
        0% {
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
            opacity: 1;
        }
        100% {
            transform: translate(200px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
        }
    }
}
/* ---------------------------------------------------------------------------- */
/* Primary Card for window View */
@media screen and (min-width: 1000px) {
    @keyframes PrimaryP2L {
        0% {
            opacity: 1;
            z-index: 10;
        }
        100% {
            transform: translate(-100px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
    }
}
@media screen and (min-width: 1000px) {
    @keyframes primaryP2R {
        0% {
            opacity: 1;
            z-index: 0;
        }
        100% {
            transform: translate(100px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
    }
}
@media screen and (min-width: 1000px) {
    @keyframes primaryR2P {
        0% {
            transform: translate(100px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 10;
        }
        100% {
            opacity: 1;
            z-index: 10;
        }
    }
}
@media screen and (min-width: 1000px) {
    @keyframes primaryR2L {
        0% {
            transform: translate(100px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: -1;
        }
        80% {
            z-index: -1;
        }
        100% {
            transform: translate(-100px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
    }
}
@media screen and (min-width: 1000px) {
    @keyframes primaryL2P {
        0% {
            transform: translate(-100px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
        100% {
            z-index: 10;
            opacity: 1;
        }
    }
}
@media screen and (min-width: 1000px) {
    @keyframes primaryL2R {
        0% {
            transform: translate(-100px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
        100% {
            transform: translate(100px) scale(var(--downScale))
                translateY(var(--downScaleY));
            opacity: 1;
            z-index: 0;
        }
    }
}

@media screen and (min-width: 1000px) {
    @keyframes rightR2P {
        0% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
            z-index: 10;
        }
        100% {
            transform: translate(-100px);
            opacity: 1;
            z-index: 10;
        }
    }
}
/* ---------------------------------------------------------------------------- */
/* Right card for window view */
@media screen and (min-width: 1000px) {
    @keyframes rightP2L {
        0% {
            transform: translate(-100px);
            opacity: 1;
        }
        100% {
            transform: translate(-220px) translateY(var(--downScaleY))
                scale(var(--downScale));
            opacity: 1;
        }
    }
}
@media screen and (min-width: 1000px) {
    @keyframes rightL2R {
        0% {
            transform: translate(-220px) translateY(var(--downScaleY))
                scale(var(--downScale));
            opacity: 1;
            z-index: -1;
        }
        80% {
            z-index: -1;
        }
        100% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
            z-index: 0;
        }
    }
}
@media screen and (min-width: 1000px) {
    @keyframes rightL2P {
        0% {
            transform: translate(-220px) translateY(var(--downScaleY))
                scale(var(--downScale));
            opacity: 1;
            z-index: 10;
        }
        100% {
            transform: translate(-100px);
            opacity: 1;
            z-index: 10;
        }
    }
}
@media screen and (min-width: 1000px) {
    @keyframes rightP2R {
        0% {
            transform: translate(-100px);
            opacity: 1;
        }
        100% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
        }
    }
}
@media screen and (min-width: 1000px) {
    @keyframes rightR2L {
        0% {
            opacity: 1;
            transform: scale(var(--downScale)) translateY(var(--downScaleY));
            z-index: -1;
        }
        80% {
            z-index: -1;
        }
        100% {
            transform: translate(-220px) translateY(var(--downScaleY))
                scale(var(--downScale));
            opacity: 1;
        }
    }
}
